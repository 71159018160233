import { createEventHandler } from '@wix/tpa-settings';
import { SettingsEventBase } from '~commons/settings/settings-types';

type Data = { [key: string]: any };

export type SettingsEventHandler<D extends SettingsEventBase> = {
  on: <T extends D['type']>(
    eventName: T,
    cb: (args: Extract<D, { type: T }>['payload']) => void,
  ) => void;
  onSettingsClose: (cb: Function) => void;
  updateData: (data?: {}) => void;
};

export const createSettingsEventHandler = <D extends SettingsEventBase>(
  data: Data,
): SettingsEventHandler<D> => {
  const handler = createEventHandler<any>(data);
  return {
    on: (eventName, cb) => {
      handler.on(eventName, cb);
    },
    // reset event is emitted when settings panel is closed
    onSettingsClose: handler.onReset,
    updateData: handler.notify,
  };
};
