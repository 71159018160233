export type CTAEffect = 'Default' | 'Hover';

export const SET_CTA_EFFECT = 'set-cta-effect' as const;

export const setCTAEffect = (payload: CTAEffect) => ({
  type: SET_CTA_EFFECT,
  payload,
});

export type SettingsEvent = ReturnType<typeof setCTAEffect>;
