import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createSettingsEventHandler } from '~commons/settings/create-settings-event-handler';
import { SET_CTA_EFFECT, SettingsEvent } from './common/settings/events';
import { ThankYouWidgetProps, ViewType } from '~/components/ThankYouWidget/Widget/types';
import { ThankYouView } from '~commons/types';

const createController: CreateControllerFn = async (controllerParams) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component/ooi-component#controller
  const settingsEventHandler = createSettingsEventHandler<SettingsEvent>(
    controllerParams.controllerConfig.config.publicData.COMPONENT ?? {},
  );
  return {
    async pageReady() {
      const flowAPI = controllerParams.flowAPI;
      const { setProps, wixCodeApi } = flowAPI.controllerConfig;

      const { view, ctaLink } = wixCodeApi.location.query;

      const defaultState: ThankYouWidgetProps & { fitToContentHeight: boolean } = {
        ctaState: 'Default',
        ctaLink: decodeURIComponent(ctaLink),
        view: parseViewQueryParam(view),
        isEditor: flowAPI.environment.isEditor,
        isPreview: flowAPI.environment.isPreview,
        // https://wix.slack.com/archives/CAKBA7TDH/p1646144638497679?thread_ts=1643623637.998229&cid=CAKBA7TDH
        fitToContentHeight: true,
      };

      setProps(defaultState);

      settingsEventHandler.on(SET_CTA_EFFECT, (payload) => {
        setProps({ ...defaultState, ctaState: payload });
      });

      settingsEventHandler.onSettingsClose(() => setProps(defaultState));
    },
    updateConfig(_, { publicData }) {
      settingsEventHandler.updateData(publicData.COMPONENT ?? {});
    },
  };
};

const parseViewQueryParam = (view: string): ViewType => {
  switch (view as ThankYouView) {
    case 'review-exists':
      return 'REVIEW_ALREADY_EXISTS';
    case 'entity-not-found':
      return 'ENTITY_NOT_FOUND';
    default:
      return 'DEFAULT';
  }
};

export default createController;
